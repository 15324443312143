import request from "@/utils/request";
//登录
export function doLogin(data) {
    return request({
        url:"/wanlin-cloud-platform/user/login",
        method: 'post',
        data: data
    })
}
//查询用户userType
export function userDetiles(data) {
    return request({
        url:"/wanlin-cloud-platform/user/info",
        method: 'post',
        data: data
    })
}








