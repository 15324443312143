import Cookies from 'js-cookie'

export function setCookie(name, value, expires) {
     Cookies.set(name, value, { expires: expires })
}
export function getCookie(name) {
     return Cookies.get(name)
}

export function removeCookie(name) {
     Cookies.remove(name)
}
