<template>
  <div class="app-list">
    <div class="search-bar">
      <el-input v-model="searchText" placeholder="请输入应用名称"></el-input>
      <el-button type="primary" @click="search">搜索</el-button>
      <el-button type="success" @click="dialogFormVisible=true" >新增</el-button>
    </div>
    <el-table :data="appList" style="width: 100%">
      <el-table-column prop="applicationName" label="应用名称"></el-table-column>
      <el-table-column prop="applicationType" label="应用类型">
          <template v-slot="scope">
            {{ scope.row.applicationType === 0 ? '内部系统' : '外部系统' }}
          </template>
      </el-table-column>
      <el-table-column prop="redirectUrl" label="跳转链接"></el-table-column>
      <el-table-column prop="sort" label="排序"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
<!--      <el-table-column label="操作">-->
<!--        <template v-slot="scope">-->
<!--              <el-button type="text" size="small" @click="del(scope.$index)">删除</el-button>&ndash;&gt;-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
  </div>
  <!-- 弹出层修复信息 -->
  <el-dialog v-model="dialogFormVisible"  style="margin-top: -10%" title="新增应用">
    <el-form :model="form">
      <el-form-item label="是否内部系统" :label-width="formLabelWidth">
        <el-select v-model="form.applicationType" placeholder="请选择">
          <el-option label="是" value="0" />
          <el-option label="否" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="应用名称" :label-width="formLabelWidth">
        <el-input v-model="form.applicationName" autocomplete="off" />
      </el-form-item>
      <el-form-item label="排序" :label-width="formLabelWidth">
        <el-input v-model="form.sort" autocomplete="off" />
      </el-form-item>
      <el-form-item label="跳转地址" :label-width="formLabelWidth">
        <el-input v-model="form.redirectUrl" autocomplete="off" />
      </el-form-item>
      <el-form-item label="备注" :label-width="formLabelWidth">
        <el-input v-model="form.remark" autocomplete="off" />
      </el-form-item>
      <!--        <el-form-item label="应用图标" :label-width="formLabelWidth">-->
      <!--          <el-upload-->
      <!--              ref="upload"-->
      <!--              class="upload-demo"-->
      <!--              action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"-->
      <!--              :limit="1"-->
      <!--              :on-exceed="handleExceed"-->
      <!--              :auto-upload="false">-->
      <!--            <template #trigger>-->
      <!--              <el-button type="primary">选择文件</el-button>-->
      <!--            </template>-->
      <!--            <el-button class="ml-3" type="success" @click="submitUpload">-->
      <!--              上传至服务器-->
      <!--            </el-button>-->
      <!--            <template #tip>-->
      <!--              <div class="el-upload__tip text-red">-->
      <!--                仅限制上传一个文件，且文件类型为图片-->
      <!--              </div>-->
      <!--            </template>-->
      <!--          </el-upload>-->
      <!--        </el-form-item>-->
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="add">
          提交
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {addAppList, getAppList} from "@/api/application";
import swal from "sweetalert";

export default {
  data() {
    return {
      searchText: '',
      appList: [],
      dialogFormVisible:false,
      form:{
        applicationType:'',
        applicationName:'',
        sort:'',
        applicationIcon:"http://dummyimage.com/100x100",
        remark:'',
        redirectUrl:''
      }
    };
  },
  mounted() {
    //初始化渲染数据
    this.initializationAPPList()
  },
  methods: {
    initializationAPPList(){
        getAppList({

        }).then((res)=>{
          this.appList=res.data.list
        })
    },
    search() {
      this.appList=[]
      getAppList({
        applicationName:this.searchText
      }).then((res)=>{
        this.appList=res.data.list
      })
    },
    add() {
      var info="";
      if(this.form.applicationName==''){
        info="请填写应用名称"
      }else if(this.form.sort==''){
        info="请填写序号"
      }else if(this.form.applicationType==''){
        info="请填写应用类型"
      }else if(this.form.remark==''){
        info="请填写备注"
      }else if(this.form.redirectUrl==''){
        info="请填写跳转链接"
      }
      if(info==""){
        this.dialogFormVisible=false
        addAppList(this.form).then((res)=>{
           swal(res.message)
        })
      }else{
        swal(info)
      }
    },
    del() {

    },
  },
};
</script>

<style>
.app-list {
  padding: 20px;
}
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>