<template>
  <div class="count-warp" :style="{width:this.windowWidth+'px',height:this.windowHeight+'px'}">
    <!--  左侧导航栏 -->
    <div class="left-nav">
      <!--顶部引导栏  -->
      <div class="space">
        <div class="nav-title">
          <img src="../resources/ICO.jpg" style="margin-left: 15px">
          <span class="nav-title-span">万林集团应用中心</span>
        </div>
      </div>
      <!-- 导航item-->
      <ul v-for="(item, index) in navItems" :key="index">
        <li @click="chooseNav(index)" :style="{backgroundColor:item.bgColor}">
          <img v-bind:src="item.imgSrc" style="margin-left: 15px">
          <span style="padding-left: 25px">{{ item.name }}</span>
        </li>
      </ul>
    </div>
    <!--主容器  -->
    <div class="main-count">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import {userDetiles} from "@/api/login";
export default {
  name: "Home_List",
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      navItems: [],
    };
  },
  mounted() {
    //判断用户是否具备登录权限
    this.judgeUser()
    //初始化调整页面位置
    this.getWindowSize();
    //初始化加载应用路由
    this.$router.push('/ApplicationList');
  },
  methods: {
    //调整主容器的大小
    getWindowSize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    //切换导航栏
    chooseNav(index) {
      for (let k = 0; k < this.navItems.length; k++) {
        this.navItems[k].bgColor = "#FFFFFF"
      }
      this.$router.push(this.navItems[index].routerUrl);
      if (this.navItems[index].bgColor == "#FFFFFF") {
        this.navItems[index].bgColor = "#F0F4F7"
      }
    },
    //判断用户是否可以查看应用管理
    judgeUser(){
      this.navItems=[]
      userDetiles().then((res)=>{
        if(res.data.userType==2||res.data.userType==1){
          this.navItems.push({name: '应用列表', imgSrc: require('../resources/list.png'), bgColor: '#F0F4F7', routerUrl: "/ApplicationList"})
          this.navItems.push({name: '应用管理', imgSrc: require('../resources/mangerico.png'), bgColor: '#FFFFFF', routerUrl: "/ApplicationManagement"})
        }else{
          this.navItems.push({name: '应用列表', imgSrc: require('../resources/list.png'), bgColor: '#F0F4F7', routerUrl: "/ApplicationList"})
        }
      })
    }
  },
}
</script>

<style scoped>
.nav-title-span{
    line-height: 25px;
    margin-top: -15px;
    font-size: 20px;
    margin-left: 10px;
}

.count-warp {
  background-color: #F0F4F7;
  float: left;
}
.left-nav {
  width: 15%;
  height: 100%;
  background-color: #FFFFFF;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  float: left;
}
.main-count {
  margin-top: 1%;
  width: 80%;
  margin-left: 2%;
  height: 98%;
  float: left;
  border-radius: 10px;
}
.space {
  width: 90%;
  height: 11%;
  border: 1px solid #FFFFFF;
}
.left-nav ul {
  width: 90%;
  height: 10%;
  list-style: none;
  padding: 0;
  margin-left: 10%;
}

.left-nav li {
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  height: 100%;
}

.left-nav li:hover {
  color: #1269DB;
}

.left-nav li img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.left-nav li span {
  font-size: 16px;
}

.nav-title {
  display: flex;
  align-items: center;
  margin-top: 10%;
}

.nav-title img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-top: -13px;
}

.nav-title h1 {
  font-size: 24px;
  font-weight: bold;
  color: black;
}
</style>