import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home'
import Login from '../views/Login'
import ApplicationList from "@/views/ApplicationList";
import ApplicationManagement from "@/views/ApplicationManagement";
import {getCookie} from "@/utils/cookie";
//加载路由
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      meta:{
        requiresAuth: true
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true
      },
      children:[
        {
          path:'/ApplicationManagement',
          name:'ApplicationManagement',
          component:ApplicationManagement,
          meta:{
            requiresAuth: true
          }
        },{
          path:'/ApplicationList',
          name:'ApplicationList',
          component:ApplicationList,
          meta:{
            requiresAuth: true
          }
        }
      ]
    }
  ]
})

//添加全局路由守卫，跳转前进行配置
router.beforeEach((to, from, next) => {
  if (getCookie("Authorization")==null&&to.name!=="Login") {
    next("/")
  } else {
    next()
  }
})

export default router
